import Vuex from 'vuex';
import { createLocalVue } from '@vue/test-utils';

import PaymentMethodsHub from './PaymentMethodsHub';

const localVue = createLocalVue();
localVue.use(Vuex);

const store = new Vuex.Store(PaymentMethodsHub);

const commonCollection = [
  {
    name: 'payment-1',
    uuid: 'A',
    type: 'mastercard',
    payment_method_type: 'card',
    userProfile: [{
      uuid: '1',
    }],
  },
  {
    name: 'payment-B2C',
    uuid: 'B',

    type: 'visa',
    payment_method_type: 'card',
    userProfile: [{
      uuid: '2',
    }],
  },
];

const B2BCollection = [
  {
    name: 'payment-B2B',
    uuid: 'C',
    type: 'visa',
    payment_method_type: 'card',
    default: true,
  },
];

describe('Given PaymentMethodsStore', () => {
  beforeEach(() => {
    jest.resetModules();
  });

  afterEach(() => {
    jest.clearAllMocks();
  });

  describe('When the "getDefaultPaymentMethodUUID" is called', () => {
    describe('And the collection is empty', () => {
      it('Then the response is null', async () => {
        store.state.paymentMethodsCollection = [];

        const profile = {
          isBusinessInCompanyPays: undefined,
          profileUuid: '',
        };

        expect(store.getters.getDefaultPaymentMethodUUID(profile)).toBe(null);
      });
    });

    describe('And the collection is from user', () => {
      it('Then the response is "A"', () => {
        const profile = {
          isBusinessInCompanyPays: false,
          profileUuid: '1',
          userIsAdmin: false,
        };

        const payload = {
          profile,
          paymentMethodsCollection: commonCollection,
        };

        store.dispatch('savePaymentMethodsCollection', payload);

        expect(store.getters.getDefaultPaymentMethodUUID(profile)).toBe('A');
      });
    });

    describe('And the collection is from company', () => {
      describe('And is a company in a company pays', () => {
        it('Then the response is "C"', () => {
          const profile = {
            isBusinessInCompanyPays: true,
            userIsAdmin: false,
            isBusinessProfile: true,
          };

          const payload = {
            profile,
            paymentMethodsCollection: B2BCollection,
          };

          store.dispatch('savePaymentMethodsCollection', payload);

          expect(store.getters.getDefaultPaymentMethodUUID(profile)).toBe('C');
        });
      });

      describe('And is company in a employee pays', () => {
        it('Then the response is null', () => {
          const profile = {
            isBusinessInCompanyPays: false,
            isBusinessProfile: true,
          };

          const payload = {
            profile,
            paymentMethodsCollection: B2BCollection,
          };

          store.dispatch('savePaymentMethodsCollection', payload);

          expect(store.getters.getDefaultPaymentMethodUUID(profile)).toBe(null);
        });
      });
    });
  });

  describe('When the "getDefaultOppositePaymentMethodUUID" is called', () => {
    describe('And the collection is from personal profile', () => {
      it('Then the response is "B"', () => {
        const profile = {
          isBusinessInCompanyPays: false,
          profileUuid: '1',
          userIsAdmin: false,
        };

        const payload = {
          profile,
          paymentMethodsCollection: commonCollection,
        };

        store.dispatch('savePaymentMethodsCollection', payload);

        expect(store.getters.getDefaultOppositePaymentMethodUUID(profile)).toBe('B');
      });
    });

    describe('And the collection is from business profile in a company on employee pays', () => {
      it('Then the response is "A"', () => {
        const profile = {
          isBusinessInCompanyPays: false,
          profileUuid: '2',
          isBusinessProfile: true,
        };

        const payload = {
          profile,
          paymentMethodsCollection: commonCollection,
        };

        store.dispatch('savePaymentMethodsCollection', payload);
        expect(store.getters.getDefaultOppositePaymentMethodUUID(profile)).toBe('A');
      });
    });
  });

  describe('When the "getPaymentMethodByUUID" is called', () => {
    describe('And customer is not using a business profile', () => {
      it('Then the B2C saved payment method is returned', () => {
        const profile = {
          isBusinessProfile: false,
          isBusinessInCompanyPays: false,
          userIsAdmin: false,
        };

        const payload = {
          profile,
          paymentMethodsCollection: commonCollection,
        };

        store.dispatch('savePaymentMethodsCollection', payload);

        expect(store.getters.getPaymentMethodByUUID('B', profile)).toStrictEqual(
          {
            name: 'payment-B2C',
            uuid: 'B',
            type: 'visa',
            payment_method_type: 'card',
            userProfile: [{
              uuid: '2',
            }],
          },
        );
      });
    });

    describe('And customer is using a business profile in employee pays', () => {
      it('Then the B2C saved payment method is returned', () => {
        const profile = {
          isBusinessProfile: true,
          isBusinessInCompanyPays: false,
          userIsAdmin: false,
        };

        const payload = {
          profile,
          paymentMethodsCollection: commonCollection,
        };

        store.dispatch('savePaymentMethodsCollection', payload);

        expect(store.getters.getPaymentMethodByUUID('B', profile)).toStrictEqual(
          {
            name: 'payment-B2C',
            uuid: 'B',
            type: 'visa',
            payment_method_type: 'card',
            userProfile: [{
              uuid: '2',
            }],
          },
        );
      });
    });

    describe('And customer is using a business profile', () => {
      it('Then the B2B saved payment method is returned', () => {
        const profile = {
          isBusinessProfile: true,
          isBusinessInCompanyPays: true,
          userIsAdmin: false,
        };

        const payload = {
          profile,
          paymentMethodsCollection: B2BCollection,
        };

        store.dispatch('savePaymentMethodsCollection', payload);

        expect(store.getters.getPaymentMethodByUUID('C', profile)).toStrictEqual(
          {
            name: 'payment-B2B',
            uuid: 'C',
            type: 'visa',
            payment_method_type: 'card',
            default: true,
          },
        );
      });
    });
  });

  describe('When the "getFilteredPaymentMethodsCollection" is called', () => {
    describe('And the collection has mixed payment method types', () => {
      const mixedCommonCollection = [
        {
          name: 'payment-1',
          uuid: 'A',
          type: 'mastercard',
          payment_method_type: 'card',
          userProfile: [{
            uuid: '1',
          }],
          internal: false,
        },
        {
          name: 'payment-B2C',
          uuid: 'B',

          type: 'visa',
          payment_method_type: 'card',
          userProfile: [{
            uuid: '2',
          }],
          internal: false,
        },
        {
          name: 'payment-B2B',
          uuid: 'C',
          type: 'visa',
          payment_method_type: 'card',
          default: true,
          internal: false,
        },
        {
          name: 'payment-B2B-iban',
          uuid: 'D',
          type: 'iban',
          payment_method_type: 'sepa',
          default: true,
          internal: false,
        },
        {
          name: 'payment-B2B-bt',
          uuid: 'E',
          type: 'bank_transfer',
          payment_method_type: 'bank_transfer',
          default: true,
          internal: true,
        },
        {
          name: 'payment-B2B-other-internal',
          uuid: 'F',
          type: 'compensation',
          payment_method_type: 'compensation',
          default: true,
          internal: true,
        },
      ];

      describe('And the user is B2C', () => {
        it('Then the user only see cards payment methods', () => {
          const profile = {
            isBusinessProfile: false,
            isBusinessInCompanyPays: false,
            userIsAdmin: false,
          };

          const payload = {
            profile,
            paymentMethodsCollection: mixedCommonCollection,
          };

          store.dispatch('savePaymentMethodsCollection', payload);

          expect(store.state.paymentMethodsCollectionB2C).toStrictEqual(
            [
              {
                name: 'payment-1',
                uuid: 'A',
                type: 'mastercard',
                payment_method_type: 'card',
                userProfile: [{
                  uuid: '1',
                }],
                internal: false,
              },
              {
                name: 'payment-B2C',
                uuid: 'B',

                type: 'visa',
                payment_method_type: 'card',
                userProfile: [{
                  uuid: '2',
                }],
                internal: false,
              },
              {
                name: 'payment-B2B',
                uuid: 'C',
                type: 'visa',
                payment_method_type: 'card',
                default: true,
                internal: false,
              },
            ],
          );
        });
      });

      describe('And the user is B2B', () => {
        it('Then the user see the collection without internal payment methods', () => {
          const profile = {
            isBusinessProfile: true,
            isBusinessInCompanyPays: true,
            userIsAdmin: true,
          };

          const payload = {
            profile,
            paymentMethodsCollection: mixedCommonCollection,
          };

          store.dispatch('savePaymentMethodsCollection', payload);

          expect(store.state.paymentMethodsCollectionB2B).toStrictEqual([
            {
              name: 'payment-1',
              uuid: 'A',
              type: 'mastercard',
              payment_method_type: 'card',
              userProfile: [{
                uuid: '1',
              }],
              internal: false,
            },
            {
              name: 'payment-B2C',
              uuid: 'B',
              type: 'visa',
              payment_method_type: 'card',
              userProfile: [{
                uuid: '2',
              }],
              internal: false,
            },
            {
              name: 'payment-B2B',
              uuid: 'C',
              type: 'visa',
              payment_method_type: 'card',
              default: true,
              internal: false,
            },
            {
              name: 'payment-B2B-iban',
              uuid: 'D',
              type: 'iban',
              payment_method_type: 'sepa',
              default: true,
              internal: false,
            },
            {
              name: 'payment-B2B-bt',
              uuid: 'E',
              type: 'bank_transfer',
              payment_method_type: 'bank_transfer',
              default: true,
              internal: true,
            },
          ]);
        });
      });

      describe('And the user is an employee pays company', () => {
        it('Then the user see the collection without internal payment methods', () => {
          const profile = {
            isBusinessProfile: true,
            isBusinessInCompanyPays: false,
            userIsAdmin: true,
          };

          const payload = {
            profile,
            paymentMethodsCollection: mixedCommonCollection,
          };

          store.dispatch('savePaymentMethodsCollection', payload);

          expect(store.state.paymentMethodsCollectionB2B).toStrictEqual([
            {
              name: 'payment-1',
              uuid: 'A',
              type: 'mastercard',
              payment_method_type: 'card',
              userProfile: [{
                uuid: '1',
              }],
              internal: false,
            },
            {
              name: 'payment-B2C',
              uuid: 'B',

              type: 'visa',
              payment_method_type: 'card',
              userProfile: [{
                uuid: '2',
              }],
              internal: false,
            },
            {
              name: 'payment-B2B',
              uuid: 'C',
              type: 'visa',
              payment_method_type: 'card',
              default: true,
              internal: false,
            },
          ]);
        });
      });
    });
  });
});
